<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #121212 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading" flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense>
                        <v-col cols="12">
                            <v-switch
                                v-model="status"
                                label="Status"
                                color="green"
                                false-value="inactive"
                                true-value="active"
                                inset
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="title"
                                :rules="[formRules.required]"
                                label="Title"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="requiredPoints"
                                :rules="[formRules.required]"
                                label="Required Points"
                                type="number"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="description"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Description"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-textarea>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-text-field
                                v-model="rewardsCondition"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Reward Condition"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,
            
            snackbar: false,
            snackText: null,

            id: null,
            title: null,
            description: null,
            requiredPoints: null,
            rewardsCondition: null,
            status: 'active',

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length <= 10) || 'Must be less than or equal to 10 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'This cannot be just spaces',
            }
        }
     },
     methods: {
        initAdd(){
            this.dialog = true
            this.main_title = 'Add Product'
            this.sub_title = 'Seham product information'
            this.action = 'add'

            this.status = 'active'
        },
        initUpdate(data){
            this.dialog = true
            this.main_title = 'Edit Product'
            this.sub_title = 'Seham product information'
            this.action = 'edit'

            this.id = data._id
            this.title = data.title
            this.description = data.description
            this.status = data.status
            this.requiredPoints = data.requiredPoints
            this.rewardsCondition = data.rewardsCondition
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('seham-admin-accesstoken')
                
                let formData = {
                    id: this.id,
                    title: this.title,
                    description: this.description,
                    requiredPoints: this.requiredPoints,
                    status: this.status
                }

                this.loading = true
                if(this.action == 'add'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/store/product/add', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data.status == 200){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = res.data.message
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("seham-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                else if(this.action == 'edit'){
                    axios.put(process.env.VUE_APP_SERVER_URL + '/api/admin/store/product/update', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = res.data.message
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("seham-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>